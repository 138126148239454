import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { validateLongevityObjective } from '../../../utils/validations';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';

export const assetAdvisoryFormSchema = z.object({
  clp_capital_markets_investment: z.number(),
  usd_capital_markets_investment: z.number(),
  clp_alt_assets: z.number(),
  usd_alt_assets: z.number(),
  apv: z.number(),
  other_investments: z.number(),

  clp_pension_plan: z.number(),
  usd_pension_plan: z.number(),
  clp_real_state_assets: z.number(),
  usd_real_state_assets: z.number(),
  private_company_stakes: z.number(),

  mortgage_credit: z.number(),
  children_education: z.number(),
  current_consumer_credit: z.number(),
  future_real_state_funds: z.number(),
  other_obligations: z.number(),

  incomes: z.number(),
  real_state_rent: z.number(),
  financial_products: z.number(),
  wants_sell_actives: z.string(),
  other_incomes: z.number(),

  objective: z.string(),
  longevity_objective: z.string(),
}).refine(({ objective, longevity_objective }) => (
  validateLongevityObjective(objective, longevity_objective)
), {
  path: ['longevity_objective'],
  message: VALIDATION_MESSAGES.REQUIRED_FIELD,
});

export type AssetAdvisoryFormValues = z.infer<typeof assetAdvisoryFormSchema>;

const ASSETS_SECTION_FIELDS: Array<keyof AssetAdvisoryFormValues> = [
  'clp_capital_markets_investment',
  'usd_capital_markets_investment',
  'clp_alt_assets',
  'usd_alt_assets',
  'apv',
  'other_investments',
  'clp_pension_plan',
  'usd_pension_plan',
  'clp_real_state_assets',
  'usd_real_state_assets',
  'private_company_stakes',
  'mortgage_credit',
  'children_education',
  'current_consumer_credit',
  'other_obligations',
  'future_real_state_funds',
  'other_obligations',
  'incomes',
  'real_state_rent',
  'financial_products',
  'wants_sell_actives',
  'other_incomes',
];

export function useAssetAdvisoryForm() {
  const methods = useForm<AssetAdvisoryFormValues>({
    resolver: zodResolver(assetAdvisoryFormSchema),
    defaultValues: {
      objective: '',
      longevity_objective: '',
    },
  });
  const [showObjectivesSection, setShowObjectivesSection] = useState(false);

  const handleAssetsSectionNextStep = async () => {
    try {
      const isClientSectionValid = await methods.trigger(ASSETS_SECTION_FIELDS);

      if (isClientSectionValid && !showObjectivesSection) {
        setShowObjectivesSection(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (values: AssetAdvisoryFormValues) => {
    // TODO: hanlde submit form should be managed when backend defines the data processing
    // eslint-disable-next-line no-console
    console.log(values);
  };

  return {
    methods,
    showObjectivesSection,
    handleSubmit,
    handleAssetsSectionNextStep,
  };
}

export function useAssetAdvisoryAccordions() {
  const { watch } = useFormContext<AssetAdvisoryFormValues>();

  const {
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    other_investments,
    usd_alt_assets,
    usd_capital_markets_investment,
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
    current_consumer_credit,
    future_real_state_funds,
    mortgage_credit,
    other_obligations,
    financial_products,
    incomes,
    other_incomes,
    real_state_rent,
    wants_sell_actives,
    children_education,
  } = watch();

  const isMarketInvestmentsAccordionFilled = [
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    other_investments,
    usd_alt_assets,
    usd_capital_markets_investment,
  ].every(Boolean);

  const isOtherAssetsAccordionFilled = [
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
  ].every(Boolean);

  const isObligationsAccordionFilled = [
    current_consumer_credit,
    future_real_state_funds,
    children_education,
    mortgage_credit,
    other_obligations,
  ].every(Boolean);

  const isIncomesAccordionFilled = [
    financial_products,
    incomes,
    other_incomes,
    real_state_rent,
    wants_sell_actives,
  ].every(Boolean);

  const areAllAccordionsFilled = isMarketInvestmentsAccordionFilled
    && isOtherAssetsAccordionFilled
    && isObligationsAccordionFilled
    && isIncomesAccordionFilled;

  return {
    isMarketInvestmentsAccordionFilled,
    isOtherAssetsAccordionFilled,
    isObligationsAccordionFilled,
    isIncomesAccordionFilled,
    areAllAccordionsFilled,
  };
}
