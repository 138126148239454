/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { AdvisorySection } from './AdvisorySection';
import { useAssetAdvisoryForm } from './useAssetAdvisoryForm';
import { ObjectivesSection, ObjectivesSectionPlaceholder } from '../common/components/ObjectivesSection';
import { getCSRF } from '../../../utils/forms';
import { createRecommendationValidatedEvent } from '../common/events';

export function AssetAdvisoryForm() {
  const {
    methods,
    handleAssetsSectionNextStep,
    showObjectivesSection,
    handleSubmit,
  } = useAssetAdvisoryForm();
  const { objective } = methods.watch();

  const { name: CSRFName, value: CSRFValue } = getCSRF();
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    window.dispatchEvent(createRecommendationValidatedEvent('asset_advisory', methods.formState.isValid && Boolean(objective)));
  }, [methods.formState, objective]);

  return (
    <FormProvider {...methods}>
      <form
        id="asset-advisory-form"
        className="row justify-content-center g-5"
        method="post"
        onSubmit={methods.handleSubmit(handleSubmit)}
        ref={formRef}
      >
        <input type="hidden" name={CSRFName} value={CSRFValue} />
        <input type="hidden" name="objective" value="OTHER" />
        <input type="hidden" name="longevity_objective" value="" />

        <div className="col-lg-5 col-2xl-4">
          <AdvisorySection
            showObjectivesSection={showObjectivesSection}
            handleNextStep={handleAssetsSectionNextStep}
          />
        </div>

        <div className="col-lg-7 col-2xl-6">
          {showObjectivesSection
            ? <ObjectivesSection formRef={formRef} /> : <ObjectivesSectionPlaceholder />}
        </div>
      </form>
    </FormProvider>
  );
}
