import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function OtherAssetsAccordion() {
  const {
    isMarketInvestmentsAccordionFilled,
    isOtherAssetsAccordionFilled,
  } = useAssetAdvisoryAccordions();
  const canOpen = isMarketInvestmentsAccordionFilled;

  return (
    <Accordion.Item className="shadow-none" eventKey="1">
      <Accordion.Button className={clsx(isOtherAssetsAccordionFilled && 'bg-success-light')} disabled={!canOpen}>
        Otros activos
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_pension_plan"
          path="clp_pension_plan"
          label="¿Tienes planes de pensiones en Chile y/o en el extranjero?"
          prefix="CLP "
          placeholder="CLP"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_pension_plan"
          path="usd_pension_plan"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_real_state_assets"
          path="clp_real_state_assets"
          label="¿Tienes activos Inmobiliarios nacionales o internacionales?"
          prefix="CLP "
          placeholder="CLP"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_real_state_assets"
          path="usd_real_state_assets"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="private_company_stakes"
          path="private_company_stakes"
          label="¿Tienes participación en algún negocio o empresa privada?"
          prefix="$ "
          placeholder="$"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
