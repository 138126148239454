/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { ClientSection } from './ClientSection';
import { InvestmentFormValues, useInvestmentForm } from './useInvestmentForm';
import { createRecommendationValidatedEvent } from '../common/events';
import { getCSRF } from '../../../utils/forms';
import { JsonPropsType } from '../../../component-loader';
import { ObjectivesSection, ObjectivesSectionPlaceholder } from '../common/components/ObjectivesSection';

export function InvestmentForm(props: JsonPropsType) {
  const defaultValues = props.jsonObject as InvestmentFormValues;

  const {
    methods,
    handleSubmit,
    handleClientSectionNextStep,
    showObjectivesSection,
  } = useInvestmentForm(defaultValues);
  const { name: CSRFName, value: CSRFValue } = getCSRF();
  const formRef = useRef<HTMLFormElement | null>(null);
  const objective = methods.watch('objective');

  useEffect(() => {
    window.dispatchEvent(createRecommendationValidatedEvent('apv_bi', methods.formState.isValid && Boolean(objective)));
  }, [methods.formState.isValid, objective]);

  return (
    <FormProvider {...methods}>
      <form
        id="investment-recommender-form"
        className="row justify-content-center g-5"
        method="post"
        onSubmit={methods.handleSubmit(handleSubmit)}
        ref={formRef}
      >
        <input type="hidden" name={CSRFName} value={CSRFValue} />
        <input type="hidden" name="objective" value="OTHER" />
        <input type="hidden" name="longevity_objective" value="" />

        <div className="col-lg-5 col-2xl-4">
          <ClientSection
            handleNextStep={handleClientSectionNextStep}
            showObjectivesSection={showObjectivesSection}
          />
        </div>
        <div className="col-lg-7 col-2xl-6">
          {showObjectivesSection
            ? <ObjectivesSection formRef={formRef} /> : <ObjectivesSectionPlaceholder />}
        </div>
      </form>
    </FormProvider>
  );
}
